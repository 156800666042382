<template>
  <v-col sm="12">
    <button
        class="tw-flex tw-items-center tw-mt-6 overview-btn"
        @click="backToAllTrip"
        :style="{ color: companyColor }"
    >
      <icons name="arrow-back" :color="companyColor" />

      <span class="tw-ml-4">All Trips</span>
    </button>
    <v-col
        sm="12"
        class="
        tw-bg-white tw-mt-9 tw-rounded-xl lg:tw-pl-16 tw-py-10 lg:tw-pr-32 tw-w-full
      "
    >
      <div
          class="tw-flex tw-w-full tw-items-center tw-justify-between lg:tw-mb-10 tw-mb-5"
      >
        <p class="edit-header" :style="{ color: companyColor }">New Trip</p>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(addATrip)">

          <v-row class="tw-flex tw-flex-col lg:tw-flex-row tw-mb-8">
            <v-col lg="4">
              <div class="form-label-div">
                <p class="label">Trip Type</p>
                <p class="label-info">Choose a trip with a single or multiple drop-offs.</p>
              </div>
            </v-col>
            <v-col lg="8" class="tw-flex tw-flex-row  tw-justify-between">
              <v-radio-group v-model="tripType" class="tw-w-full tw-justify-between tw-flex tw-items-center"  :row="$vuetify.breakpoint.mdAndUp" hide-details>
                <div :style="{width : $vuetify.breakpoint.mdAndUp ? '52%': '100%'}">
                  <v-radio value="single drop-off" label="Single Drop-Off" :color="companyColor"></v-radio>
                </div>
                <div :style="{width : $vuetify.breakpoint.mdAndUp ? '48%': '100%'}" class="tw-my-5 lg:tw-my-0">
                  <v-radio value="multiple drop-offs" label="Multiple Drop-Offs" :color="companyColor"></v-radio>
                </div>
              </v-radio-group>
            </v-col>
          </v-row>
          <div
              class="tw-flex tw-w-full tw-items-center  tw-mb-5 tw-mt-16 lg:tw-mb-10 lg:tw-mt-0"
          >
          <v-row class="tw-flex tw-flex-col lg:tw-flex-row ">
            <v-col lg="4">
              <div class="form-label-div">
                <p class="label">Date (s)</p>
                <p class="label-info">Choose the date (s) and time for the trip</p>
              </div>
            </v-col>
            <v-col lg="8" class="tw-flex tw-flex-row tw-justify-between">
              <div style="width: 48%">
                <ValidationProvider
                    name="Trip Date"
                    rules="required"
                    v-slot="{ classes, errors }"
                >
                  <div class="" :class="classes">
                    <v-menu
                        ref="fromDateMenu"
                        v-model="fromDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="tripDates"
                            v-on="on"
                            placeholder="2022-09-9"
                            solo
                            hide-details
                            readonly
                        >
                          <template #prepend-inner>
                            <v-icon :color="companyColor" class="">
                              mdi-calendar-month-outline
                            </v-icon>
                          </template></v-text-field
                        >
                      </template>
                        <v-date-picker
                                locale="en-in"
                                v-model="tripDates"
                                no-title
                                :min="minDate"
                                :color="companyColor"
                                multiple
                                scrollable
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                    text
                                    :color="companyData.companyColor"
                                    @click="fromDateMenu = false">
                                Cancel
                            </v-btn>
                            <v-btn text :color="companyData.companyColor"
                                   @click="$refs.fromDateMenu.save(tripDates)">OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>

              <div style="width: 48%">
                <ValidationProvider
                    name="Time"
                    rules="required"
                    v-slot="{ classes, errors }"
                >
                  <div class="" :class="classes">
                    <v-combobox :color="companyColor"
                                v-model="tripTime"
                                :items="timeOfTheDay"
                                placeholder="6:00AM"
                                append-icon="mdi-chevron-down"
                                solo
                                hide-details
                    >
                      <template #prepend-inner>
                        <v-icon :color="companyColor" class="">
                          mdi-clock-outline
                        </v-icon>
                      </template>
                    </v-combobox>

                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </v-col>
          </v-row>
          </div>
          <div
              class="tw-flex tw-w-full tw-items-center  tw-mb-5 tw-mt-16 lg:tw-mb-10 lg:tw-mt-0"
          >
            <v-row class="tw-flex"  >
                <div class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-items-center" v-for="(route,index) in mergeRoutes" :key="index">

                    <v-col lg="4" class="">
                        <div class="form-label-div">
                            <p class="label">Route</p>
                            <p class="label-info">Select the route for the trip.</p>
                        </div>
                    </v-col>
                    <v-col lg="8" class="tw-flex tw-flex-row tw-w-full tw-justify-between" :style="{flexDirection : !route.tripRegion || route.tripRegion === 'local' ? 'row': 'column'}">
                        <ValidationProvider
                                name="Route"
                                rules="required"
                                v-slot="{ classes, errors }"
                                :style="{width :!route.tripRegion || route.tripRegion === 'local' ? '48%' : '100%' }"
                        >
                            <div class="" :class="classes">
                                <v-combobox
                                        :items="routes"
                                        placeholder="Choose Route"
                                        append-icon="mdi-chevron-down"
                                        :item-text="getRouteText"
                                        item-value="id"
                                        v-model="route.route"
                                        solo
                                        hide-details
                                        :color="companyColor"
                                        class="text-capitalize"
                                >
                                    <template #prepend-inner>
                                        <icons name="routes"  :color="companyColor"/>
                                    </template>
                                    <template v-slot:item="{ item }">
                      <span class="text-capitalize"
                      >{{ toSentenceCase(item.departureTerminalName) }}
                        {{ toSentenceCase(item.departureTerminalCity) }}
                        {{ toSentenceCase(item.departureTerminalState) }} -
                        {{ toSentenceCase(item.destinationTerminalName) }}
                        {{ toSentenceCase(item.destinationTerminalCity) }}
                        {{ toSentenceCase(item.destinationTerminalState) }}</span
                      >
                                    </template>
                                </v-combobox>
                                <span>{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                        <div :style="{width : !route.tripRegion || route.tripRegion === 'local' ? '48%' : '100%' }" class="tw-flex tw-flex-row" >
                            <div class="tw-flex tw-w-11/12" v-if="!route.tripRegion || route.tripRegion === 'local'">
                            <ValidationProvider
                                    name="Route Amount"
                                    rules="required"
                                    v-slot="{ classes, errors }"
                                    class="tw-w-full"
                            >
                                <div class="" :class="classes">
                                    <v-text-field :color="companyColor"
                                                  v-model="route.routeAmount"
                                                  placeholder="8000"
                                                  solo
                                                  hide-details
                                                  type="Number"
                                                  prefix="₦"
                                    >
                                    </v-text-field>
                                    <span>{{ errors[0] }}</span>
                                </div>
                            </ValidationProvider>
                            </div>
                            <div class="tw-flex tw-w-11/12 tw-flex-col" v-else>
                                <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-items-start lg:tw-items-center tw-justify-between tw-py-3" v-for="(tripCategory,index) in route.tripCategories" :key="index">
                                    <v-checkbox  v-model="tripCategory.selected" :color="companyColor">
                                        <template #label>
                                            <label class="text-capitalize">{{tripCategory.type}}</label>
                                        </template>
                                    </v-checkbox>
                                    <ValidationProvider
                                            name="Route Amount"
                                            rules="required"
                                            v-slot="{ classes, errors }">
                                        <div class="" :class="classes">
                                            <v-text-field :color="companyColor"
                                                          v-model="tripCategory.tripAmount"
                                                          placeholder="8000"
                                                          solo
                                                          hide-details
                                                          type="Number"
                                                          prefix="₦"
                                            >
                                            </v-text-field>
                                            <span>{{ errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="tw-w-auto">
                                <v-tooltip top :color="companyColor">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-col v-bind="attrs" class="tw-cursor-pointer"
                                               v-on="on" @click="addPickupRoute">
                                            <icons   name="circular-add" :color="companyColor"></icons>
                                        </v-col>
                                    </template>
                                    <span>Add route</span>
                                </v-tooltip>
                            </div>
                            <div class="tw-w-auto" v-if="mergeRoutes.length > 1">
                                <v-col  class="tw-cursor-pointer"
                                        @click="removePickupRoute(index)">
                                    <icons   name="confirm-delete"  height="20" width="20" :color="companyColor"></icons>
                                </v-col>
                            </div>
                        </div>

                    </v-col>
                </div>
            </v-row>
          </div>
          <div
              class="tw-flex tw-w-full tw-items-center  tw-mb-5 tw-mt-16 lg:tw-mb-10 lg:tw-mt-0"
          >
            <v-row class="tw-flex tw-flex-col lg:tw-flex-row">
                <v-col lg="4" class="">
                    <div class="form-label-div">
                        <p class="label">Vehicle</p>
                        <p class="label-info">Select vehicle type & seat capacity.</p>
                    </div>
                </v-col>
                <v-col class="tw-flex tw-flex-row tw-w-full lg:tw-w-8/12 tw-justify-between">
                    <div style="width: 48%">
                        <ValidationProvider
                                name="Vehicle Type"
                                rules="required"
                                v-slot="{ classes, errors }"
                        >
                            <div class="" :class="classes">
                                <v-combobox :color="companyColor"
                                            v-model="selectedVehicleType"
                                            :items="vehicleTypes"
                                            placeholder="Hummer Bus"
                                            solo
                                            append-icon="mdi-chevron-down"
                                            hide-details>
                                </v-combobox>
                                <span>{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>

                    <div style="width: 48%">
                        <ValidationProvider
                                name="Vehicle Capacity"
                                rules="required"
                                v-slot="{ classes, errors }"
                        >
                            <div class="" :class="classes">
                                <v-combobox :color="companyColor"
                                            v-model="selectedVehicleCapacity"
                                            :items="vehicleCapacities"
                                            placeholder="15 Seater"
                                            solo
                                            append-icon="mdi-chevron-down"
                                            hide-details>
                                </v-combobox>
                                <span>{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>
                </v-col>
            </v-row>
          </div>
          <div
              class="tw-flex tw-w-full tw-items-center  tw-mb-5 tw-mt-14 lg:tw-mb-10 lg:tw-mt-0"
          >
          <div
              class="
              tw-flex tw-w-full tw-justify-end tw-items-center
              action-btn-div
              tw-mt-10
            "
          >
            <v-btn
                text
                class="first-btn"
                :style="{
                color: companyColor,
                border: '1px solid' + companyColor,
              }"
                @click="backToAllTrip"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                class="second-btn"
                type="submit"
                :style="{ background: companyColor }"
                :loading="doneLoading"
            >
              Done
            </v-btn>
          </div>
          </div>
        </form>
      </validation-observer>
    </v-col>
  </v-col>
</template>

<script>
import {
    getAllTransportCompanyRoute,
    addTrip, getAllCompanyFleets,
} from "@/services/api/APIService";
import Icons from "../../reuseables/Icons";
import cities from "@/assets/nigeriaCities";

export default {
  name: "TerminalManagerAddTrip",
  components: { Icons },
  props: {
    main: {
      type: [Boolean],
      default: true,
    },
  },
  data() {
    return {
      time: null,
      menu2: false,
      modal2: false,
      fromDateMenu: false,
      fromDateVal: null,
      maxDate: "2019-08-30",
      companyColor: "#004AAD",
      routes: [{ name: "ABJ - KAD" }],
      fleets: [{ name: "BUS - KYK" }],
      status: [{ name: "pending" }, { name: "active" }],

      companyData: {},
      doneLoading: false,
      tripAmount: "",
      tripDates: null,
      tripTime: null,
      tripRouteId: "",
      staffData: {},
        mergeRoutes: [
            {
                tripRegion : "local",
              tripCategories:[
                {
                  type: "with regular passport",
                  tripAmount: null,
                  selected: false
                },
                {
                  type: "with virgin passport",
                  tripAmount: null,
                  selected: false
                },
                {
                  type: "with id Cards (NIN voter's card)",
                  tripAmount: null,
                  selected: false
                },
                {
                  type: "no identification",
                  tripAmount: null,
                  selected: false
                },
              ],
            }
        ],
      timeOfTheDay:[
        "12:00am",
        "12:15am",
        "12:30am",
        "12:45am",
        "1:00am",
        "1:15am",
        "1:30am",
        "1:45am",
        "2:00am",
        "2:15am",
        "2:30am",
        "2:45am",
        "3:00am",
        "3:15am",
        "3:30am",
        "3:45am",
        "4:00am",
        "4:15am",
        "4:30am",
        "4:45am",
        "5:00am",
        "5:15am",
        "5:30am",
        "5:45am",
        "6:00am",
        "6:15am",
        "6:30am",
        "6:45am",
        "7:00am",
        "7:15am",
        "7:30am",
        "7:45am",
        "8:00am",
        "8:15am",
        "8:30am",
        "8:45am",
        "9:00am",
        "9:15am",
        "9:30am",
        "9:45am",
        "10:00am",
        "10:15am",
        "10:30am",
        "10:45am",
        "11:00am",
        "11:15am",
        "11:30am",
        "11:45am",

        "12:00pm",
        "12:15pm",
        "12:30pm",
        "12:45pm",
        "1:00pm",
        "1:15pm",
        "1:30pm",
        "1:45pm",
        "2:00pm",
        "2:15pm",
        "2:30pm",
        "2:45pm",
        "3:00pm",
        "3:15pm",
        "3:30pm",
        "3:45pm",
        "4:00pm",
        "4:15pm",
        "4:30pm",
        "4:45pm",
        "5:00pm",
        "5:15pm",
        "5:30pm",
        "5:45pm",
        "6:00pm",
        "6:15pm",
        "6:30pm",
        "6:45pm",
        "7:00pm",
        "7:15pm",
        "7:30pm",
        "7:45pm",
        "8:00pm",
        "8:15pm",
        "8:30pm",
        "8:45pm",
        "9:00pm",
        "9:15pm",
        "9:30pm",
        "9:45pm",
        "10:00pm",
        "10:15pm",
        "10:30pm",
        "10:45pm",
        "11:00pm",
        "11:15pm",
        "11:30pm",
        "11:45pm",
      ],
      tripType: "single drop-off",
      mainRoute:"",
      mainRouteAmount:null,
      subRoutes:[
        {
          "route": null,
          "routeAmount": null
        }
      ],
      vehicleTypes : [],
      terminals : [],
      selectedPickupTerminals:[],
      selectedVehicleType : null,
      vehicleCapacities : [],
      selectedVehicleCapacity : null,

    };
  },
    watch:{
        tripRouteId : {
            handler: function (val){
                if (val){
                    console.log(val, this.terminals)
                    this.terminals = this.terminals.filter(terminal => terminal.id !== this.getRouteDepartureTerminalId(val))
                }
            },
            immediate: true
        },

        mainRoute :{
            handler : function (val){
                if (Object.keys(val).length){
                    this.terminals = this.terminals.filter(terminal => terminal.terminalName !== val.terminalName)
                }
            },
            immediate: true
        },
        mergeRoutes :{
            handler : function (val){
                if (val.length > 0){
                    this.getUnselectedRoutes()
                }
            },
            immediate: true
        },
        tripType :{
            handler : function (){
                this.mergeRoutes = [
                    {
                        tripRegion : "local",
                      tripCategories:[
                        {
                          type: "with regular passport",
                          tripAmount: null,
                          selected: false
                        },
                        {
                          type: "with virgin passport",
                          tripAmount: null,
                          selected: false
                        },
                        {
                          type: "with id Cards (NIN voter's card)",
                          tripAmount: null,
                          selected: false
                        },
                        {
                          type: "no identification",
                          tripAmount: null,
                          selected: false
                        },
                      ],
                    }
                ]
                if (this.companyData && Object.keys(this.companyData).length) {
                    this.getAllRoute()
                }
            },
            immediate: true
        }
    },
  computed: {
    fromDateDisp() {
      return this.fromDateVal;
      // format/do something with date
    },
      minDate() {
          const tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);
          return tomorrow.toISOString().substr(0, 10);
      },
    getSubRoutes(){
      let subRoutes = []
      if (this.tripType === 'multiple drop-offs' && this.mainRoute){
        this.routes.forEach(route =>{
          if (route.departureTerminalId === this.mainRoute.departureTerminalId){
            subRoutes.push(route)
          }})
        subRoutes = subRoutes.filter(route => route !== this.mainRoute)
        cities.forEach(city => {
          let route = {}
          route.departureTerminalId = this.mainRoute.departureTerminalId
          route.departureTerminalName = this.mainRoute.departureTerminalName
          route.departureTerminalCity = this.mainRoute.departureTerminalCity
          route.destinationTerminalCity = ""
          route.destinationTerminalName = city.destinationTerminalCity
          route.destinationTerminalState = city.destinationTerminalState
          subRoutes.push(route)
        })
      }
      return subRoutes
      //akinyele makinwa
    },
    getMainRouteTerminalNameAndCity(){
      let data = ""
      if (this.mainRoute){
        data = this.mainRoute.departureTerminalName+" "+this.mainRoute.departureTerminalCity
      }
      return data
    }
  },
  methods: {
    backToAllTrip() {
      this.$store.dispatch(
        "terminalManagerTripComponent/setCurrentComponent",
        "allTrips"
      );
      sessionStorage.setItem("terminalManagerTripComponent", "allTrips");
    },
      async getAllRoute() {
          await getAllTransportCompanyRoute(this.companyData.id)
              .then((res) => {
                  this.routes = res.data;
                  this.routes.sort(function (a, b) {
                      return (a.departureTerminalState.localeCompare(b.departureTerminalState) )
                  })
                  this.routes.sort(function (a, b) {
                      return (a.departureTerminalName.localeCompare(b.departureTerminalName) )
                  })
              })
              .catch((err) => {
                  console.log(err.response);
                  this.$displaySnackbar({
                      message: err.response.data.details[0],
                      success: false,
                  });
              });
      },
      async getAllFleet(){
          let vehicleTypesToUse = []
          let vehicleCapacitiesToUse = []
          this.vehicleTypes = []
          this.vehicleCapacities = []
          let uniqueVehicleTypes = new Set()
          let uniqueVehicleCapacities = new Set()
          await getAllCompanyFleets(this.companyData.id).then(res => {
              res.data.forEach(vehicle =>{
                  vehicleTypesToUse.push(vehicle.vehicleType)
                  vehicleCapacitiesToUse.push(vehicle.vehicleCapacity)})
              vehicleTypesToUse.forEach(vehicleType => uniqueVehicleTypes.add(JSON.stringify(vehicleType)))
              vehicleCapacitiesToUse.forEach(vehicleCapacity => uniqueVehicleCapacities.add(JSON.stringify(vehicleCapacity)))
              uniqueVehicleTypes.forEach(seat => this.vehicleTypes.push(JSON.parse(seat)))
              uniqueVehicleCapacities.forEach(capacity => this.vehicleCapacities.push(JSON.parse(capacity)))
              this.vehicleTypes.sort((a, b) => a.localeCompare(b))
              this.vehicleCapacities.sort(function (a, b) {
                  return (a- b);});

          })
      },
    async addATrip() {
        let tripDates = []
        this.doneLoading = true;
        let pickupRoutes = []
        let data = {};
        if (Array.isArray(this.tripDates)){
            tripDates = this.tripDates
        }
        else {
            tripDates.push(this.tripDates)
        }
        this.mergeRoutes.forEach(route =>{
            let pickupRoute = {}
            pickupRoute.id = route.route.id
            pickupRoute.tripRegion = route.tripRegion
            if (route.tripRegion === "crossCountry"){
                this.tripCategories.forEach(tripCategory =>{
                    if (tripCategory.tripAmount !== null){
                        tripCategory.selected = true
                    }
                    if (tripCategory.type.startsWith("with id")){
                        tripCategory.type = "with id cards"
                    }
                })
                pickupRoute.tripCategories =  this.tripCategories.filter(tripCategory => tripCategory.selected && tripCategory.tripAmount !== null)
            }
            else {
                pickupRoute.routeAmount = route.routeAmount
            }
            pickupRoute.departureTerminalId = route.route.departureTerminalId
            pickupRoutes.push(pickupRoute)
        })
        data.takeOffDates = tripDates;
        data.takeOffTime = this.convertTo24Hour(this.tripTime)
        data.routeId = pickupRoutes[0].id;
        data.transportCompanyId = this.companyData.id;
        data.vehicleType = this.selectedVehicleType.toLowerCase()
        data.vehicleCapacity = this.selectedVehicleCapacity
        data.pickupRoutes = pickupRoutes
      data.groupId = this.generateRandomString()
        data.mainDropOff = true
        await addTrip(data)
            .then(() => {
                this.doneLoading = false;
                this.$displaySnackbar({
                    message: "Trip created successfully!",
                    success: true,
                });
                this.backToAllTrip();
            })
            .catch((err) => {
                this.doneLoading = false;
                console.log(err.response);
                this.$displaySnackbar({
                    message: err.response.data.details[0],
                    success: false,
                });
            });

      // else if (this.tripType === 'multiple drop-offs') {
      //   this.doneLoading = true;
      //   let groupId = this.generateRandomString()
      //   let data ={}
      //   data.transportCompanyId = this.companyData.id;
      //   data.terminalId = this.mainRoute.departureTerminalId;
      //   data.takeOffDate = this.tripDates;
      //   data.takeOffTime = this.convertTo24Hour(this.tripTime)
      //   data.dropOffs= []
      //   let mainRouteData = {}
      //   mainRouteData.routeId = this.mainRoute.id
      //   mainRouteData.routeAmount = this.mainRouteAmount
      //   mainRouteData.groupId = groupId
      //   mainRouteData.mainDropOff = true
      //   data.dropOffs.push(mainRouteData)
      //   this.subRoutes.forEach(route =>{
      //     let subRouteData = {}
      //     subRouteData.routeId = route.route.id
      //     subRouteData.routeAmount = route.routeAmount
      //     subRouteData.groupId = groupId
      //     subRouteData.mainDropOff = false
      //     subRouteData.destinationTerminalName = route.route.destinationTerminalName
      //     subRouteData.destinationTerminalCity = route.route.destinationTerminalName
      //     subRouteData.destinationTerminalState = route.route.destinationTerminalState
      //     if (!subRouteData.routeId){
      //       subRouteData.routeId = 0
      //     }
      //     data.dropOffs.push(subRouteData)
      //   })
      //   console.log(data, "multiple drop-offs ................")
      //   await addMultipleTrip(data)
      //       .then(() => {
      //         this.doneLoading = false;
      //         this.$displaySnackbar({
      //           message: "Trip created successfully!",
      //           success: true,
      //         });
      //         this.backToAllTrip();
      //       })
      //       .catch((err) => {
      //         this.doneLoading = false;
      //         console.log(err.response);
      //         this.$displaySnackbar({
      //           message: err.response.data.details[0],
      //           success: false,
      //         });
      //       });
      // }
    },
    getUnselectedRoutes(){
        this.mergeRoutes.forEach(route =>{
            if (route && Object.keys(route).length && route.route && this.tripType === "single drop-off"){
                if (route.route.departureTerminalCountry.toLowerCase() !== route.route.destinationTerminalCountry.toLowerCase()){
                    route.tripRegion = "crossCountry"
                }
                this.routes = this.routes.filter(rRoute => rRoute.id !== route.route.id )
                this.routes = this.routes.filter(rRoute => rRoute.destinationTerminalName === route.route.destinationTerminalName)
            }
            else if (route && Object.keys(route).length && route.route && this.tripType === "multiple drop-offs"){
                if (route.route.departureTerminalCountry.toLowerCase() !== route.route.destinationTerminalCountry.toLowerCase()){
                    route.tripRegion = "crossCountry"
                }
                this.routes = this.routes.filter(rRoute => rRoute.id !== route.route.id )
            }
        })
    },
    generateRandomString(){
      return (Math.random()).toString(36).replace("0.", "");
    },
    convertTo24Hour(time) {
      const hours = parseInt(time.substr(0, 2));
      if(time.indexOf('am') !== -1 && hours === 12) {
        time = time.replace('12', '0');
      }
      if(time.indexOf('pm')  !== -1 && hours < 12) {
        time = time.replace(hours, (hours + 12));
      }
      let hours12 = time.replace(/(am|pm)/, '');
      let [dHours, dMinutes] = hours12.split(':');
      if (dHours.length === 1){
        hours12 = "0"+hours12
      }
      console.log(dMinutes)
      return hours12;
    },
    getRouteText(item){
      return this.toSentenceCase(item.departureTerminalName) +" "+
          this.toSentenceCase(item.departureTerminalCity) + " "+
          this.toSentenceCase(item.departureTerminalState) + " - "+
          this.toSentenceCase(item.destinationTerminalName) + " "+
          this.toSentenceCase(item.destinationTerminalCity) + " "+
          this.toSentenceCase(item.destinationTerminalState)
    },
    getRouteDestinationText(item){
      return this.toSentenceCase(item.destinationTerminalName )+ " "+
          this.toSentenceCase(item.destinationTerminalCity) + " "+
          this.toSentenceCase(item.destinationTerminalState)
    },
    addRouteDropOff(){
      let routeDropOff = {}
      this.subRoutes.push(routeDropOff)
    },
    removeRouteDropOff(selectedIndex){
      console.log(selectedIndex,"the selected index ...")
      this.subRoutes= this.subRoutes.filter((routeDropOff,index) => index !== selectedIndex)
    },
      addPickupRoute(){
          let pickupRoute = {
              tripRegion : "local",
            tripCategories:[
              {
                type: "with regular passport",
                tripAmount: null,
                selected: false
              },
              {
                type: "with virgin passport",
                tripAmount: null,
                selected: false
              },
              {
                type: "with id Cards (NIN voter's card)",
                tripAmount: null,
                selected: false
              },
              {
                type: "no identification",
                tripAmount: null,
                selected: false
              },
            ],
          }
          this.mergeRoutes.push(pickupRoute)
      },
      removePickupRoute(selectedIndex){
          this.mergeRoutes= this.mergeRoutes.filter((routeDropOff,index) => index !== selectedIndex)
          if (this.mergeRoutes.length === 1){
              this.getAllRoute()
          }
      },

    toSentenceCase(text){
          if (text) {
              text = text.toLowerCase()
              return text.replace(text.charAt(0), text.charAt(0).toUpperCase())
          }
      },
  },
  mounted() {},
  async created() {
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let staffData = JSON.parse(sessionStorage.getItem("staffData"));
    this.companyData = companyData;
    if (companyData && Object.keys(companyData).length) {
      this.companyColor = companyData.companyColor;
        await this.getAllRoute();
        await this.getAllFleet();
    }
    if (staffData) {
      this.staffData = staffData;
    }

  },
};
</script>

<style lang="scss" scoped>
.upload-div .upload-div-text {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  height: 80px;
  width: 80px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.button-logo-input .button-logo-input-img {
  width: 24.19px;
  height: 18.72px;
}
.action-btn-div {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    margin-left: 20px;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
.edit-header {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.form-label-div {
  .label {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    color: rgba(79, 79, 79, 1);
  }

  .label-info {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(79, 79, 79, 1);
    margin-bottom: 0;
  }
}

.date-time {
  width: 48%;

  @media screen and (max-width: 786px) {
    width: 100%;
  }
}
</style>
