<template>
  <Component v-bind:is="displayTerminalComponent()" />
</template>

<script>
import AllTrips from "./AllTrip.vue";
import AddTrip from "./AddTrip.vue";
import SingleTrip from "../../transportCompany/trip/SingleTrip";
import { mapGetters } from "vuex";
export default {
  name: "TerminalManagerTrips",
  computed: {
    ...mapGetters("terminalManagerTripComponent", ["currentComponent"]),
  },
  data() {
    return {};
  },
  methods: {
    displayTerminalComponent() {
      let component = this.currentComponent;
      if (component === "allTrips") {
        return AllTrips;
      } else if (component === "addTrip") {
        return AddTrip;
      }
      else if (component === "singleTrip"){
        return SingleTrip
      }
    },
  },
  created() {
    let component = sessionStorage.getItem("terminalManagerTripComponent");

    if (!component) {
      sessionStorage.setItem("terminalManagerTripComponent", "allTrips");
      this.$store.dispatch(
        "terminalManagerTripComponent/setCurrentComponent",
        "allTrips"
      );
      this.displayTerminalComponent();
    } else {
      this.$store.dispatch(
        "terminalManagerTripComponent/setCurrentComponent",
        component
      );
      this.displayTerminalComponent();
    }
  },
};
</script>

<style lang="scss" scoped></style>
